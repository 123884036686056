import React from 'react';
import { IframeFluid } from './Iframe.style';

interface IframeProps {
  title?: string;
  url?: string;
  isPromoBar?: boolean;
  promoBarOnlyMobile?: boolean;
}

const Iframe = ({ url, title, isPromoBar, promoBarOnlyMobile }: IframeProps) => {
  return (
    <IframeFluid
      src={url}
      title={title}
      isPromoBar={isPromoBar}
      promoBarOnlyMobile={promoBarOnlyMobile}
    />
  );
};

export default Iframe;
