import { isMobile, isIOS } from 'react-device-detect';
import {
  MARKET_COUNTRY_CODES,
  CountryCode,
  Market,
  OTHER_COUNTRY_CODES,
  OtherCountryCode,
  markets,
} from '@web-creditea/interfaces';
import { match } from 'ts-pattern';
import {
  MOBILE_WALLET_ANDROID_LINK,
  MOBILE_WALLET_DYNAMIC_LINK,
  MOBILE_WALLET_IOS_LINK,
} from '../../data/constants';

type CountryCodes = CountryCode | OtherCountryCode;

const getAppLinks = (
  countryCode: CountryCodes,
  isMobile: boolean
): { iOS: string; android: string } => {
  return match(isMobile)
    .with(true, () => ({
      iOS: MOBILE_WALLET_DYNAMIC_LINK,
      android: MOBILE_WALLET_DYNAMIC_LINK,
    }))
    .with(false, () => ({
      iOS: MOBILE_WALLET_IOS_LINK.replace('countryCode', countryCode),
      android: MOBILE_WALLET_ANDROID_LINK,
    }))
    .exhaustive();
};

export const getUserDevice = (market?: Market) => {
  const getCode = (market?: Market): CountryCodes => {
    return match(market)
      .with(markets.ee, () => MARKET_COUNTRY_CODES.ee)
      .with(markets.lv, () => MARKET_COUNTRY_CODES.lv)
      .with(markets.lt, () => MARKET_COUNTRY_CODES.lt)
      .with(markets.cz, () => MARKET_COUNTRY_CODES.cz)
      .with(markets.mx, () => MARKET_COUNTRY_CODES.mx)
      .otherwise(() => OTHER_COUNTRY_CODES.us);
  };

  const countryCode = getCode(market);
  const appLinks = getAppLinks(countryCode, isMobile);
  const deviceAppLink = isIOS ? appLinks.iOS : appLinks.android;

  return { isMobile, deviceAppLink, appLinks };
};
