import React, { useMemo } from 'react';
import { BaseCarousel, IconNames, Typography, CarouselCard } from '@ipf-ui/components-library';
import Image from 'next/image';
import * as Styled from './CarouselSection.style';
import type { TeamCarouselCard, TestimonialCard } from '@web-creditea/interfaces';
import { definedValues, nonNullKeys, returnMaybe } from '../../../helpers';

interface CarouselProps {
  title?: string;
  cards?: (TeamCarouselCard | TestimonialCard)[];
}

const CarouselSection = ({ title, cards }: CarouselProps) => {
  const cardsArray = useMemo(
    () =>
      cards?.map((card) => {
        return (
          <React.Fragment key={card._key}>
            {card.__typename === 'TestimonialCard' && (
              <CarouselCard
                type={card.__typename}
                description={returnMaybe(card.description)}
                iconName={card.iconName as IconNames}
                iconBackgroundColor={returnMaybe(card.iconColor?.value)}
                name={returnMaybe(card.name)}
                position={returnMaybe(card.position)}
                year={returnMaybe(card.year)}
              />
            )}
            {card.__typename === 'TeamCarouselCard' && (
              <CarouselCard
                type={card.__typename}
                image={
                  card?.image?.asset?.url && (
                    <Image
                      src={card.image.asset.url}
                      alt={card.image.customAltText ?? card.image.asset.altText ?? ''}
                      fill
                    />
                  )
                }
                socialMedia={definedValues(card.socialMedia).map(
                  (social) => nonNullKeys({ ...social, icon: social.icon as IconNames })!
                )}
                description={returnMaybe(card.description)}
                name={returnMaybe(card.name)}
                position={returnMaybe(card.position)}
                country={returnMaybe(card.country)}
              />
            )}
          </React.Fragment>
        );
      }),
    [cards]
  );
  return (
    <Styled.CarouselWrapper>
      {title && (
        <>
          <Typography.Header2>{title}</Typography.Header2>
          <Styled.ResponsiveGutter />
        </>
      )}
      <BaseCarousel cards={cardsArray} />
    </Styled.CarouselWrapper>
  );
};

export default CarouselSection;
