import React from 'react';
import Link from 'next/link';
import {
  AccordionsGroup,
  CardsWithImage,
  CTABanner,
  CTATextSection,
  Feature,
  Gutter,
  Hero,
  LinkSection,
  TwoColumnText,
  BulletList,
  BulletListItem,
  PriceInfo,
  theme,
  Table,
  AlternativeFeatures,
  ShortFaq,
  StepsSection,
  RoundedFeature,
  FlippingCards,
  Container,
  VideoPlayer,
  ColorMode,
  ButtonVariant,
  Proportions,
  AvailableGutterValues,
  IconNames,
  RoundTypes,
  ButtonConfig,
  SocialIconsNames,
  LinkFragment,
  DownloadButton,
  CardsSection,
} from '@ipf-ui/components-library';

import FeatureWithItems from '../../components/organisms/FeatureWithItems/FeatureWIthItems';
import CarouselSection from '../../components/organisms/CarouselSection/CarouselSection';
import PayLaterFormWrapper from '../../components/organisms/PayLaterForm/PayLaterFormWrapper';
import Iframe from '../../components/atoms/Iframe/Iframe';
import CampaignFormWrapper from '../../components/organisms/CampaignFormWrapper/CampaignFormWrapper';

import NextImage from 'next/image';

import {
  DocumentsSection,
  NextLinkComponent,
  PortableTextWrapper,
  SectionContainer,
  TextSection,
  FeatureListSection,
  DownloadAppButtonsWrapper,
  PromoBanner,
  // #TODO to be deleted after end of campaign
  HeroForXFactor,
} from '../../components';

import {
  AllSectionsFragment,
  PromoBarItemDataFragment,
  TeamCarouselCard,
  TestimonialCard,
  TextRaw,
  Market,
} from '@web-creditea/interfaces';
import { mapIntoContentProps } from '../contentMappers/heroContentMapper';
import { createFaqCategoryLinks } from '../contentMappers/faqHelpers';
import { tableContentMapper } from '../tableContentMaper';

import { returnMaybe, nonNullKeys, definedValues } from '../returnMaybe';
import { CSSProperties } from 'styled-components';
import { getUserDevice } from '../hooks';
import LoanFormsWrapper from '../../components/organisms/LoanFormWrapper/LoanFormsWrapper';

export const renderSections = (
  sections: Array<AllSectionsFragment>,
  faqTranslation?: string,
  market?: Market,
  downloadAppButtonsTitle?: string,
  kubernetesNamespace?: string,
  promoBarItem?: PromoBarItemDataFragment,
  urlFor?: (filename: string) => string
) => {
  return sections.map((section) =>
    renderSection(
      section,
      faqTranslation,
      market,
      downloadAppButtonsTitle,
      kubernetesNamespace,
      promoBarItem,
      urlFor
    )
  );
};

const renderSection = (
  section: AllSectionsFragment,
  faqTranslation?: string,
  market?: Market,
  downloadAppButtonsTitle?: string,
  kubernetesNamespace?: string,
  promoBarItem?: PromoBarItemDataFragment,
  urlFor?: (filename: string) => string
) => {
  const userDevice = getUserDevice(market);
  switch (section.__typename) {
    case 'AccordionsGroup':
      return (
        <SectionContainer
          id={returnMaybe(section.scrollToSection?.anchorTitle)}
          key={section._key}
          withGutterSpacing={100}
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
        >
          <AccordionsGroup
            title={returnMaybe(section.title)}
            accordions={returnMaybe(
              section.accordions
                ?.filter(Boolean)
                .map((accordion) => returnMaybe(nonNullKeys(accordion))!)
            )}
          />
        </SectionContainer>
      );
    case 'CampaignFormSection':
      return (
        <SectionContainer
          id={returnMaybe(section.scrollToSection?.anchorTitle)}
          key={section._key}
          withGutterSpacing={100}
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
        >
          <CampaignFormWrapper urlFor={urlFor} section={section} market={market} />
        </SectionContainer>
      );
    case 'CardsSection':
      return (
        <SectionContainer
          key={section._key}
          id={returnMaybe(section.scrollToSection?.anchorTitle)}
          withGutterSpacing={100}
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
        >
          <CardsSection
            title={returnMaybe(section.title)}
            description={returnMaybe(section.sectionDescription as { textRaw: TextRaw })}
            textColor={returnMaybe(section.textColor?.value)}
            backgroundColor={returnMaybe(section.backgroundColor?.value)}
            numberInRow={returnMaybe(section.numberInRow)}
            elements={returnMaybe(
              section.items?.map((element) => {
                const nonNullCard = nonNullKeys(element);
                return {
                  ...nonNullCard,
                  iconName: nonNullCard?.iconName as IconNames,
                  iconColor: nonNullKeys(nonNullCard?.iconColor),
                  textContent: nonNullCard?.textContent as { textRaw: TextRaw },
                  linkData:
                    (nonNullCard?.downloadFileButton as DownloadButton) ||
                    (nonNullCard?.link as LinkFragment),
                  buttonConfig: nonNullCard?.buttonConfig as ButtonConfig,
                };
              })
            )}
            Link={Link}
            PortableTextWrapper={PortableTextWrapper}
          />
        </SectionContainer>
      );
    case 'CardsWithImage':
      return (
        <SectionContainer
          id={returnMaybe(section.scrollToSection?.anchorTitle)}
          key={section._key}
          withGutterSpacing={100}
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
        >
          <CardsWithImage
            allCards={returnMaybe(
              section.allCards?.map((card) => {
                const nonNullCard = nonNullKeys(card);
                const columns = section.allCards && section.allCards.length <= 2 ? 2 : 3;
                return {
                  ...nonNullCard,
                  description: nonNullCard?.description as { textRaw: TextRaw },
                  image: returnMaybe(
                    nonNullCard?.image?.asset && (
                      <NextImage
                        src={nonNullCard?.image.asset?.url ?? ''}
                        alt={
                          nonNullCard?.image.customAltText ||
                          nonNullCard?.image.asset?.altText ||
                          ''
                        }
                        fill
                        sizes={`(max-width: 599) 100vw,  ${columns === 2 ? '50vw' : '33vw'}`}
                        style={{ objectFit: 'cover' }}
                      />
                    )
                  ),
                };
              })
            )}
            PortableTextWrapper={PortableTextWrapper}
          />
        </SectionContainer>
      );
    case 'CTABanner':
      return (
        <SectionContainer
          id={returnMaybe(section.scrollToSection?.anchorTitle)}
          key={section._key}
          fluid
          withoutPadding
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
        >
          <CTABanner
            key={section._key}
            color={section.textColor?.value || theme.crediteaColors.primary.neutral[800]}
            backgroundColor={
              section.backgroundColor?.value || theme.crediteaColors.secondary.lime[500]
            }
            title={returnMaybe(section.title)}
            description={returnMaybe(section.description)}
            CallToAction={
              <DownloadAppButtonsWrapper
                iconVariant={returnMaybe(section.iconBackground as ColorMode)}
                showMobileButton={false}
                userDevice={userDevice}
              />
            }
            DABOnlyMobile={section.DABOnlyMobile ?? false}
            CTAVariant={returnMaybe(section.CTAVariant)}
            linkData={returnMaybe(nonNullKeys(section.link))}
            buttonConfig={returnMaybe({
              ...section.buttonConfig,
              colorMode: section.buttonConfig?.colorMode as ColorMode,
              variant: section.buttonConfig?.variant as ButtonVariant,
              __typename: 'ButtonConfig',
            })}
            nextLink={Link}
            appLinks={userDevice.appLinks}
            googlePlayRating={returnMaybe(section.googlePlayRating)}
            appStoreRating={returnMaybe(section.appStoreRating)}
          />
        </SectionContainer>
      );
    case 'CTATextSection':
      return (
        <SectionContainer
          id={returnMaybe(section.scrollToSection?.anchorTitle)}
          key={section._key}
          fluid
          withoutPadding
          withGutterSpacing={100}
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
        >
          <CTATextSection
            backgroundColor={
              section.backgroundColor?.value || theme.crediteaColors.primary.neutral[50]
            }
            title={returnMaybe(section.ctaTitle)}
            textColor={returnMaybe(section.textColor?.value)}
            description={section.ctaDescription as { textRaw: TextRaw }}
            nextLink={Link}
            linkData={returnMaybe(nonNullKeys(section.ctaButton))}
            PortableTextWrapper={PortableTextWrapper}
            buttonConfig={returnMaybe({
              ...section.buttonConfig,
              colorMode: section.buttonConfig?.colorMode as ColorMode,
              variant: section.buttonConfig?.variant as ButtonVariant,
              __typename: 'ButtonConfig',
            })}
          />
        </SectionContainer>
      );
    case 'Feature':
      return (
        <SectionContainer
          id={returnMaybe(section.scrollToSection?.anchorTitle)}
          key={section._key}
          withGutterSpacing={100}
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
          fluid
          withoutPadding
        >
          <Feature
            title={returnMaybe(section.title)}
            subtitle={returnMaybe(section.subTitle)}
            nextLink={Link}
            linkData={returnMaybe(nonNullKeys(section.link))}
            linkAsButton={returnMaybe(section.linkAsButton)}
            textContent={section.featureContent as { textRaw: TextRaw }}
            nextImage={returnMaybe(
              section?.image?.asset && (
                <NextImage
                  src={section.image?.asset.url ?? ''}
                  alt={section.image?.customAltText || section.image.asset.altText || ''}
                  sizes="
                (max-width: 599) 100vw,
                (max-width: 1239) 75vw,
                50vw"
                  fill
                />
              )
            )}
            reverse_position={returnMaybe(section.reverse_position)}
            proportions={returnMaybe(section.proportions as Proportions)}
            bgColor={section.backgroundColor?.value ?? theme.crediteaColors.primary.neutral[50]}
            textColor={returnMaybe(section.textColor?.value)}
            hideImageOnMobile={section.hideImageOnMobile}
            PortableTextWrapper={PortableTextWrapper}
            videoUrl={section?.videoFile?.asset?.url ?? ''}
            linksArray={returnMaybe(
              definedValues(section.linksArray).map((link) =>
                nonNullKeys({
                  ...link,
                } as LinkFragment | DownloadButton)
              ) as (LinkFragment | DownloadButton)[]
            )}
            buttonConfig={returnMaybe(section?.buttonConfig as ButtonConfig)}
          />
        </SectionContainer>
      );
    case 'Hero':
      return (
        <Hero
          key={section._key}
          content={mapIntoContentProps({
            content: returnMaybe(definedValues(section.content)),
            downloadAppButtonsTitle,
            market,
            textColorTitle: returnMaybe(section.textColor?.value),
            kubernetesNamespace,
          })}
          backgroundColor={returnMaybe(section.backgroundColor?.value)}
          titleColor={returnMaybe(section.titleColor?.value)}
          textColor={returnMaybe(section.textColor?.value)}
          rightBottomCornerRounded={returnMaybe(section.rightBottomCornerRounded)}
          align={returnMaybe(section.alignOptions as 'top' | 'middle' | 'bottom' | undefined)}
          topPadding={returnMaybe(section.topPadding as AvailableGutterValues)}
          bottomPadding={returnMaybe(section.bottomPadding as AvailableGutterValues)}
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
          promoBarOnlyMobile={returnMaybe(promoBarItem?.promoBarOnlyMobile)}
        />
      );
    case 'StepsSection':
      return (
        <SectionContainer
          id={returnMaybe(section.scrollToSection?.anchorTitle)}
          key={section._key}
          withGutterSpacing={100}
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
        >
          <StepsSection
            key={section._key}
            title={returnMaybe(section.title)}
            backgroundColor={returnMaybe(section.backgroundColor?.value)}
            textColor={returnMaybe(section.textColor?.value)}
            sectionDescription={returnMaybe(section.sectionDescription as { textRaw: TextRaw })}
            elements={returnMaybe(
              definedValues(section.items).map(
                (section) =>
                  nonNullKeys({
                    ...section,
                    iconColor: nonNullKeys(section.iconColor),
                    textContent: section.textContent as { textRaw: TextRaw },
                  })!
              )
            )}
            PortableTextWrapper={PortableTextWrapper}
          />
        </SectionContainer>
      );
    case 'LinkSection':
      return (
        <SectionContainer
          key={section._key}
          id={returnMaybe(section.scrollToSection?.anchorTitle)}
          withGutterSpacing={100}
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
          fluid
          withoutPadding
        >
          <Container>
            <LinkSection
              links={createFaqCategoryLinks(definedValues(section.items), faqTranslation)}
              NextLinkComponent={NextLinkComponent}
            />
          </Container>
        </SectionContainer>
      );
    case 'LoanForm':
      return (
        <SectionContainer
          id={returnMaybe(section.scrollToSection?.anchorTitle)}
          key={section._key}
          withGutterSpacing={100}
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
          fluid
          withoutPadding
        >
          <LoanFormsWrapper
            market={market}
            kubernetesNamespace={kubernetesNamespace}
            contentProps={section}
          />
        </SectionContainer>
      );
    case 'AlternativeFeatures':
      return (
        <SectionContainer
          id={returnMaybe(section.scrollToSection?.anchorTitle)}
          key={section._key}
          withGutterSpacing={100}
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
          fluid
          withoutPadding
        >
          <AlternativeFeatures
            elements={returnMaybe(
              definedValues(section.items).map(
                (element) =>
                  nonNullKeys({
                    ...element,
                    iconName: element.iconName as IconNames,
                    iconColor: nonNullKeys(element.iconColor),
                    link: nonNullKeys(element.link),
                    textContent: element.textContent as { textRaw: TextRaw },
                  })!
              )
            )}
            title={returnMaybe(section.title)}
            description={returnMaybe(section.description)}
            alignment={returnMaybe(section.alignment as CSSProperties['textAlign'])}
            Link={Link}
            PortableTextWrapper={PortableTextWrapper}
            textColor={returnMaybe(section.textColor?.value)}
            horizontal={returnMaybe(section.horizontal)}
            backgroundColor={returnMaybe(section.backgroundColor?.value)}
          />
        </SectionContainer>
      );
    case 'RoundedFeature':
      return (
        <SectionContainer
          id={returnMaybe(section.scrollToSection?.anchorTitle)}
          key={section._key}
          withGutterSpacing={100}
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
          fullWidthOnMobile={returnMaybe(section.fullWidthOnMobile)}
        >
          <RoundedFeature
            key={section._key}
            backgroundColor={returnMaybe(section.backgroundColor?.value)}
            textColor={returnMaybe(section.textColor?.value)}
            narrowVersion={returnMaybe(section.narrowVersion)}
            cornersType={returnMaybe(section.cornersType as RoundTypes)}
            fullWidthOnMobile={returnMaybe(section.fullWidthOnMobile)}
            title={returnMaybe(section.title)}
            description={returnMaybe(section.textContent as { textRaw: TextRaw })}
            PortableTextWrapper={PortableTextWrapper}
            nextImage={returnMaybe(
              section.backgroundImage?.asset && (
                <NextImage
                  src={section.backgroundImage?.asset.url ?? ''}
                  alt={
                    section.backgroundImage?.customAltText ??
                    section.backgroundImage?.asset.altText ??
                    ''
                  }
                  fill
                  sizes="
                (max-width: 599) 100vw,
                (max-width: 904) 75vw,
                50vw"
                />
              )
            )}
            nextLink={Link}
            linkData={returnMaybe(nonNullKeys(section.link))}
            linkAsButton={returnMaybe(section.linkAsButton)}
            buttonConfig={returnMaybe(section.buttonConfig as ButtonConfig)}
            socialList={returnMaybe(
              definedValues(section.socialList).map(
                (listItem) =>
                  nonNullKeys({
                    ...listItem,
                    icon: listItem.icon as SocialIconsNames,
                    iconBackgroundColor: nonNullKeys(listItem.iconBackgroundColor),
                    iconFillColor: nonNullKeys(listItem.iconFillColor),
                  })!
              )
            )}
          />
        </SectionContainer>
      );
    case 'ShortFaq':
      return (
        <SectionContainer
          id={returnMaybe(section.scrollToSection?.anchorTitle)}
          key={section._key}
          withGutterSpacing={100}
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
        >
          <ShortFaq
            key={section._key}
            title={returnMaybe(section.title)}
            accordions={returnMaybe(
              definedValues(section.faq).map(
                (faq) => nonNullKeys({ ...faq, content: faq.content as { textRaw: TextRaw } })!
              )
            )}
            nextLink={Link}
            linkData={returnMaybe(nonNullKeys(section.link))}
            PortableTextWrapper={PortableTextWrapper}
          />
        </SectionContainer>
      );
    case 'PriceInfo':
      return (
        <SectionContainer
          id={returnMaybe(section.scrollToSection?.anchorTitle)}
          key={section._key}
          withGutterSpacing={100}
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
        >
          <PriceInfo
            image={
              section.image?.asset && (
                <NextImage
                  src={section.image.asset.url ?? ''}
                  alt={section.image.customAltText ?? section.image.asset.altText ?? ''}
                  fill
                  sizes="
                  (max-width: 599) 100vw, 50vw"
                />
              )
            }
            price={returnMaybe(section.price)}
            period={returnMaybe(section.period)}
            promotionLabel={returnMaybe(section.promotionLabel)}
            title={returnMaybe(section.title)}
            type={returnMaybe(section.type)}
            linkData={returnMaybe(nonNullKeys(section.link))}
            nextLink={Link}
            categories={returnMaybe(
              definedValues(section.categories).map(
                (category) =>
                  nonNullKeys({
                    ...category,
                    list: category.list?.map((item) => nonNullKeys(item)!),
                  })!
              )
            )}
          />
        </SectionContainer>
      );
    case 'TextSection':
      return (
        <SectionContainer
          id={returnMaybe(section.scrollToSection?.anchorTitle)}
          key={section._key}
          withGutterSpacing={100}
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
        >
          <TextSection
            title={section.title}
            titleAlignment={section.titleAlignment}
            isSectionFullWidth={section.isSectionFullWidth}
            titleFontSize={section.titleFontSize}
          >
            {section.text && (
              <PortableTextWrapper
                urlFor={urlFor}
                portableText={section.text?.textRaw as TextRaw}
              />
            )}
          </TextSection>
          {section.showCookieTable && <div id="ot-sdk-cookie-policy" />}
        </SectionContainer>
      );
    case 'DocumentsSection':
      return (
        <SectionContainer
          id={returnMaybe(section.scrollToSection?.anchorTitle)}
          key={section._key}
          withGutterSpacing={100}
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
        >
          <DocumentsSection
            key={section._key}
            documentLinks={returnMaybe(definedValues(section.links))}
            title={returnMaybe(section.title)}
          ></DocumentsSection>
        </SectionContainer>
      );
    case 'TwoColumnSection':
      return (
        <SectionContainer
          id={returnMaybe(section.scrollToSection?.anchorTitle)}
          key={section._key}
          withGutterSpacing={100}
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
        >
          <TwoColumnText
            title={returnMaybe(section.title)}
            twoColumns={returnMaybe(section.twoColumns)}
          >
            {section.bulletList && (
              <BulletList>
                {section.bulletList.map((listItem) => (
                  <BulletListItem key={listItem}>{listItem}</BulletListItem>
                ))}
              </BulletList>
            )}
          </TwoColumnText>
        </SectionContainer>
      );
    case 'FeatureList':
      return (
        <SectionContainer
          id={returnMaybe(section.scrollToSection?.anchorTitle)}
          key={section._key}
          fluid
          withoutPadding
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
        >
          <FeatureListSection key={section._key} {...section} />
        </SectionContainer>
      );
    case 'TableSection':
      return (
        <SectionContainer
          id={returnMaybe(section.scrollToSection?.anchorTitle)}
          key={section._key}
          withGutterSpacing={60}
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
        >
          <Table
            variant={returnMaybe(section.variant)}
            caption={returnMaybe(section.caption)}
            rows={tableContentMapper(
              section.tableData,
              Boolean(section.hasHeaderRow),
              Boolean(section.hasHeaderColumn)
            )}
          />
        </SectionContainer>
      );
    case 'Gutter':
      return (
        <Gutter
          key={section._key}
          size={returnMaybe(section.spacingDesktop)}
          sizeMobile={returnMaybe(section.spacingMobile)}
        />
      );
    case 'PromoBanner':
      return (
        <SectionContainer
          id={returnMaybe(section.scrollToSection?.anchorTitle)}
          key={section._key}
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
          fluid
          withoutPadding
        >
          <PromoBanner
            countrySelectPlaceholder={returnMaybe(section.countrySelectPlaceholder)}
            phoneInputPlaceholder={returnMaybe(section.phoneInputPlaceholder)}
            title={returnMaybe(section.title)}
            backgroundColor={returnMaybe(section.backgroundColor?.value)}
            textColor={returnMaybe(section.textColor?.value)}
            buttonText={returnMaybe(section.buttonText)}
            image={returnMaybe(section.image)}
            buttonConfig={returnMaybe(section.buttonConfig as ButtonConfig)}
          />
        </SectionContainer>
      );
    case 'FlippingCardsSection':
      return (
        <SectionContainer
          id={returnMaybe(section.scrollToSection?.anchorTitle)}
          key={section._key}
          withGutterSpacing={100}
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
          fluid
          withoutPadding
        >
          <FlippingCards
            heading={returnMaybe(section.heading)}
            textColor={returnMaybe(section.textColor?.value)}
            backgroundColor={returnMaybe(section.backgroundColor?.value)}
            elements={returnMaybe(
              definedValues(section.items).map(
                (card) => nonNullKeys({ ...card, iconColor: nonNullKeys(card.iconColor) })!
              )
            )}
          />
        </SectionContainer>
      );
    case 'FeatureWithItems':
      return (
        <SectionContainer
          id={returnMaybe(section.scrollToSection?.anchorTitle)}
          key={section._key}
          withGutterSpacing={100}
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
        >
          <FeatureWithItems
            featureVariant={section.featureVariant}
            title={section.title}
            image={section.image}
            accordionsList={section.accordionsList}
            iconItemsList={section.iconItemsList}
            link={section.link}
          />
        </SectionContainer>
      );
    case 'TeamCarousel':
      return (
        <SectionContainer
          id={returnMaybe(section.scrollToSection?.anchorTitle)}
          key={section._key}
          withGutterSpacing={125}
          fluid
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
        >
          <CarouselSection
            title={returnMaybe(section.title)}
            cards={returnMaybe(
              definedValues(section.cards).map((card) =>
                nonNullKeys({
                  ...card,
                } as TeamCarouselCard | TestimonialCard)
              ) as (TeamCarouselCard | TestimonialCard)[]
            )}
          />
        </SectionContainer>
      );
    case 'PayLaterForm':
      return (
        <SectionContainer
          id={returnMaybe(section.scrollToSection?.anchorTitle)}
          key={section._key}
          withGutterSpacing={100}
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
          fluid
          withoutPadding
        >
          <PayLaterFormWrapper
            title={section.formTitle?.textRaw as TextRaw}
            subtitle={section.subtitle?.textRaw as TextRaw}
            market={market}
            campaign={returnMaybe(section.campaign)}
          />
        </SectionContainer>
      );
    case 'VideoPlayer':
      return (
        <SectionContainer
          id={returnMaybe(section.scrollToSection?.anchorTitle)}
          key={section._key}
          withGutterSpacing={100}
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
        >
          <VideoPlayer url={section?.videoFile?.asset?.url ?? ''} />
        </SectionContainer>
      );
    case 'Iframe':
      return (
        <Iframe
          url={returnMaybe(section.url)}
          title={returnMaybe(section.title)}
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
          promoBarOnlyMobile={returnMaybe(promoBarItem?.promoBarOnlyMobile)}
        />
      );
    // #TODO to be deleted after end of campaign
    case 'HeroForXFactor':
      return (
        <HeroForXFactor
          title={returnMaybe(section.title)}
          textContent={returnMaybe(section.textContent as { textRaw: TextRaw })}
          isPromoBar={returnMaybe(promoBarItem?.showPromoBar)}
          promoBarOnlyMobile={returnMaybe(promoBarItem?.promoBarOnlyMobile)}
        />
      );
    default:
      return null;
  }
};
