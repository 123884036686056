import styled, { css } from 'styled-components';

export interface IFrameProps {
  isPromoBar?: boolean;
  promoBarOnlyMobile?: boolean;
}

const navbarHeight = 74;
const promoBarMobileHeight = 80;
const promoBarDesktopHeight = 58;

const navbarHeightWithPromoBar = () => css`
  padding-top: ${navbarHeight + promoBarMobileHeight}px;
  box-shadow: inset 0 154px 0 #10465b;
  ${({ theme }) => theme.breakpoints.sm} {
    padding-top: ${navbarHeight + promoBarDesktopHeight}px;
    box-shadow: inset 0 132px 0 #10465b;
  }
  ${({ theme }) => theme.breakpoints.md} {
    padding-top: ${navbarHeight + promoBarDesktopHeight}px;
  }
`;

const navbarHeightWithSpacing = () => css`
  padding-top: ${navbarHeight}px;
  box-shadow: inset 0 74px 0 #10465b;
  ${({ theme }) => theme.breakpoints.sm} {
    padding-top: ${navbarHeight}px;
  }
  ${({ theme }) => theme.breakpoints.md} {
    padding-top: ${navbarHeight}px;
  }
`;

export const IframeFluid = styled.iframe<IFrameProps>`
  ${({ isPromoBar }) => (isPromoBar ? navbarHeightWithPromoBar() : navbarHeightWithSpacing())};
  ${({ theme }) => theme.breakpoints.sm} {
    ${({ isPromoBar, promoBarOnlyMobile }) =>
      isPromoBar && !promoBarOnlyMobile ? navbarHeightWithPromoBar() : navbarHeightWithSpacing()}
  }
  width: 100%;
  border: 0;
  overflow: hidden;
  height: 2900px;
  z-index: 0;
  position: relative;

  @media only screen and (min-width: 380px) {
    height: 3150px;
  }
  @media only screen and (min-width: 412px) {
    height: 3250px;
  }
  @media only screen and (min-width: 450px) {
    height: 3400px;
  }
  @media only screen and (min-width: 480px) {
    height: 3500px;
  }
  @media only screen and (min-width: 500px) {
    height: 3600px;
  }
  @media only screen and (min-width: 520px) {
    height: 3700px;
  }
  @media only screen and (min-width: 540px) {
    height: 3750px;
  }
  @media only screen and (min-width: 570px) {
    height: 3850px;
  }
  @media only screen and (min-width: 590px) {
    height: 3950px;
  }
  @media only screen and (min-width: 620px) {
    height: 4100px;
  }
  @media only screen and (min-width: 652px) {
    height: 4300px;
  }
  @media only screen and (min-width: 690px) {
    height: 4500px;
  }
  @media only screen and (min-width: 730px) {
    height: 4700px;
  }
  @media only screen and (min-width: 768px) {
    height: 2700px;
  }
  @media only screen and (min-width: 850px) {
    height: 2850px;
  }
  @media only screen and (min-width: 930px) {
    height: 3000px;
  }
  @media only screen and (min-width: 992px) {
    height: 2850px;
  }
  @media only screen and (min-width: 1024px) {
    height: 2150px;
  }
  @media only screen and (min-width: 1100px) {
    height: 2250px;
  }
`;
