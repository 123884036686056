import React, { useState, useEffect } from 'react';
import loanFormData from '../../../data/products/creditlineMX.json';
import {
  LoanForm,
  Slider,
  SliderProps,
  Markets,
  currencyFormatMX,
  ColorModeForButton,
} from '@ipf-ui/components-library';
import { swapSubdomain } from '../../../helpers/createMenu';

const DIVISOR = 100;
const SAMPLE_PERIOD_VALUE = 60;
const SAMPLE_INTEREST_RATE = 1.5;

const loanValues = loanFormData.map((loanVariant) => {
  return loanVariant.sum / 100;
});

export type LoanFormWrapperMXProps = {
  sliderVariant: Markets;
  currencySymbol: string;
  interestRate: number;
  amountSlider: SliderProps;
  periodSlider: SliderProps;
  termsDescription: string;
  title: string;
  description: string;
  refundsLabel: string;
  buttonText: string;
  buttonUrl: string;
  anchorTarget?: boolean;
  colorMode?: ColorModeForButton;
  kubernetesNamespace: string;
  backgroundColor?: {
    value: string;
  };
};

const LoanFormWrapperMX = ({
  amountSlider,
  termsDescription,
  title,
  description,
  refundsLabel,
  buttonText,
  buttonUrl,
  anchorTarget,
  colorMode,
  kubernetesNamespace,
}: LoanFormWrapperMXProps) => {
  const [amountInputValue, setAmountInputValue] = useState<number>(loanFormData[0].sum / DIVISOR);
  const [monthlyPaymentValue, setMonthlyPaymentValue] = useState<number>(0);
  const sliders = [
    {
      ...amountSlider,
      value: amountInputValue,
      marks: loanValues,
      type: Slider.Types.Primary,
      format: currencyFormatMX,
      onChange: (value: number) => setAmountInputValue(value),
    },
  ];

  const applyButton = `${swapSubdomain(
    buttonUrl,
    kubernetesNamespace
  )}?principal=${amountInputValue}&maturity=${SAMPLE_PERIOD_VALUE}&signupgate=true&homeSlider=false`;

  const loanFormProps = {
    sliders,
    termsDescription,
    periodValue: SAMPLE_PERIOD_VALUE,
    interestRate: SAMPLE_INTEREST_RATE,
    format: currencyFormatMX,
    callToAction: {
      title,
      description,
      refundsLabel,
      buttonText,
      colorMode,
      buttonAction: () => window.open(applyButton, anchorTarget === false ? '_blank' : '_self'),
    },
  };

  useEffect(() => {
    const index = loanFormData.findIndex((loanVariant) => {
      return loanVariant.sum / DIVISOR === amountInputValue;
    });
    setMonthlyPaymentValue(loanFormData[index].mmp / DIVISOR);
  }, [amountInputValue]);

  return (
    <>
      <LoanForm {...loanFormProps} market={Markets.Latvia} paymentValue={monthlyPaymentValue} />
    </>
  );
};

export default LoanFormWrapperMX;
